import {AnalyticsStateMachine} from '../../analyticsStateMachines/AnalyticsStateMachine';
import {EventDispatcher} from '../../core/EventDispatcher';
import {Event, EventMap} from '../../enums/Event';
import {AnalyticsStateMachineOptions} from '../../types/AnalyticsStateMachineOptions';
import {CustomDataValues} from '../../types/CustomDataValues';
import {DownloadSpeedInfo} from '../../types/DownloadSpeedInfo';
import {DrmPerformanceInfo} from '../../types/DrmPerformanceInfo';
import {NoExtraProperties} from '../../types/NoExtraProperties';
import {SegmentInfo} from '../../types/SegmentInfo';
import {defaultStateMachineCallbacks, StateMachineCallbacks} from '../../types/StateMachineCallbacks';
import {logger} from '../../utils/Logger';
import {ANALYTICS_QUALITY_CHANGE_COUNT_THRESHOLD} from '../../utils/Settings';
import {getCurrentTimestamp} from '../../utils/Utils';
import {WindowEventTracker} from '../../utils/WindowEventTracker';

export abstract class InternalAdapter {
  public stateMachineCallbacks: StateMachineCallbacks = {...defaultStateMachineCallbacks};
  // tslint:enable:no-unused-expression
  protected stateMachine!: AnalyticsStateMachine;

  protected opts: AnalyticsStateMachineOptions;
  protected _onLicenseKeyReceived: EventDispatcher<{licenseKey: string}> = new EventDispatcher();
  protected _onLicenseCallFailed: EventDispatcher<{}> = new EventDispatcher();

  protected drmPerformanceInfo: DrmPerformanceInfo | undefined = undefined;
  protected previousVideoBitrate: number = 0;
  protected previousAudioBitrate: number = 0;
  protected qualityChangeCount: number = 0;
  protected abstract get currentTime(): number;

  private _windowEventTracker: WindowEventTracker = new WindowEventTracker();

  get windowEventTracker(): WindowEventTracker {
    return this._windowEventTracker;
  }

  get onLicenseKeyReceived() {
    return this._onLicenseKeyReceived;
  }

  get onLicenseCallFailed() {
    return this._onLicenseCallFailed;
  }

  get downloadSpeedInfo(): DownloadSpeedInfo {
    return {
      segmentsDownloadCount: 0,
      segmentsDownloadSize: 0,
      segmentsDownloadTime: 0,
      avgDownloadSpeed: 0,
      minDownloadSpeed: 0,
      maxDownloadSpeed: 0,
      avgTimeToFirstByte: 0,
    };
  }

  get segments(): SegmentInfo[] {
    return [];
  }

  constructor(opts?: AnalyticsStateMachineOptions) {
    if (!opts) {
      opts = {
        starttime: undefined,
      };
    }
    if (!opts.starttime) {
      opts.starttime = getCurrentTimestamp();
    }

    this.opts = opts;
  }

  public release() {
    this.stateMachine.resetIntervals();
    this.stateMachineCallbacks.release();
    this.resetSourceRelatedState();
    this._windowEventTracker.release();
  }

  public eventCallback = <StatemachineEvent extends keyof EventMap, EventData extends EventMap[StatemachineEvent]>(
    eventType: StatemachineEvent,
    eventObject: NoExtraProperties<EventMap[StatemachineEvent], EventData>
  ) => {
    eventObject = eventObject || {};
    if (!this.stateMachine) {
      logger.log("Bitmovin Analytics: StateMachine isn't ready yet");
    } else {
      this.stateMachine.callEvent(eventType, eventObject, getCurrentTimestamp());
    }
  };

  public getCommonPlaybackInfo() {
    return {
      screenHeight: screen.height,
      screenWidth: screen.width,
    };
  }

  public clearValues(): void {
    // noop by default
  }

  public clearSegments(): void {
    // noop by default
  }

  public increaseQualityChangeCount(): void {
    this.qualityChangeCount++;
  }

  public resetQualityChangeCount(): void {
    this.qualityChangeCount = 0;
  }

  public resetSourceRelatedState(): void {
    this.drmPerformanceInfo = undefined;
  }

  public isQualityChangeEventEnabled(): boolean {
    return this.qualityChangeCount <= ANALYTICS_QUALITY_CHANGE_COUNT_THRESHOLD;
  }

  public setCustomData(values: CustomDataValues): void {
    const previousState = this.stateMachine.currentState;

    if (
      this.stateMachine.currentState &&
      (this.stateMachine.currentState === 'PAUSE' || this.stateMachine.currentState === 'PLAYING')
    ) {
      this.eventCallback(Event.CUSTOM_DATA_CHANGE, {
        currentTime: this.currentTime,
      });
      this.eventCallback(Event[previousState], {
        values,
        currentTime: this.currentTime,
      });
    } else {
      this.stateMachineCallbacks.customdatachange(undefined, undefined, {values});
    }
  }

  protected shouldAllowVideoQualityChange(newBitrate: number | undefined): boolean {
    return (
      newBitrate != null &&
      !isNaN(newBitrate) &&
      this.previousVideoBitrate !== newBitrate &&
      this.isQualityChangeEventEnabled()
    );
  }

  protected setPreviousVideoBitrate(newBitrate: number): void {
    this.previousVideoBitrate = newBitrate;
  }

  protected shouldAllowAudioQualityChange(newBitrate: number | undefined): boolean {
    return (
      newBitrate != null &&
      !isNaN(newBitrate) &&
      this.previousAudioBitrate !== newBitrate &&
      this.isQualityChangeEventEnabled()
    );
  }

  protected setPreviousAudioBitrate(newBitrate: number): void {
    this.previousAudioBitrate = newBitrate;
  }
}
