interface EventListener {
  eventName: string;
  func: EventListenerOrEventListenerObject;
}

export class WindowEventTracker {
  private trackedObjects: EventListener[] = [];

  public addEventListener(eventName: string, func: EventListenerOrEventListenerObject) {
    this.trackedObjects.push({eventName, func});
    window.addEventListener(eventName, func);
  }

  public release() {
    while (this.trackedObjects.length !== 0) {
      const obj = this.trackedObjects.pop();
      if (obj === undefined) {
        continue;
      }

      window.removeEventListener(obj.eventName, obj.func);
    }
  }
}
