import {Analytics} from '../../core/Analytics';
import {Event} from '../../enums/Event';
import {MIMETypes} from '../../enums/MIMETypes';
import {Player} from '../../enums/Player';
import {ErrorDetailBackend} from '../../features/errordetails/ErrorDetailBackend';
import {ErrorDetailTracking} from '../../features/errordetails/ErrorDetailTracking';
import {Feature} from '../../features/Feature';
import {FeatureConfig} from '../../features/FeatureConfig';
import {AnalyticsStateMachineOptions} from '../../types/AnalyticsStateMachineOptions';
import {FeatureConfigContainer} from '../../types/FeatureConfigContainer';
import {QualityLevelInfo} from '../../types/QualityLevelInfo';
import {HTML5InternalAdapter} from './HTML5InternalAdapter';
import {InternalAdapterAPI} from './InternalAdapterAPI';

export class DashjsInternalAdapter extends HTML5InternalAdapter implements InternalAdapterAPI {
  constructor(private mediaPlayer: any, opts?: AnalyticsStateMachineOptions) {
    super(null, opts);
  }

  /**
   * @override
   */
  public initialize(analytics: Analytics): Array<Feature<FeatureConfigContainer, FeatureConfig>> {
    super.initialize(analytics);
    let videoEl: HTMLVideoElement | null = null;
    let canPlay = false;
    try {
      videoEl = this.mediaPlayer.getVideoElement();
    } catch (e) {} // tslint:disable-line:no-empty

    if (!videoEl) {
      this.mediaPlayer.on(
        'canPlay',
        () => {
          if (canPlay) {
            return;
          }
          videoEl = this.mediaPlayer.getVideoElement();
          canPlay = true;
          this.setMediaElement(videoEl);
          this.registerMediaElementEventsForDashJS();
        },
        this
      );
    } else {
      this.setMediaElement(videoEl);
      this.registerMediaElementEventsForDashJS();
    }
    const errorDetailTracking = new ErrorDetailTracking(
      analytics.errorDetailTrackingSettingsProvider,
      new ErrorDetailBackend(analytics.errorDetailTrackingSettingsProvider.collectorConfig),
      [analytics.errorDetailSubscribable],
      undefined
    );
    return [errorDetailTracking];
  }

  public registerMediaElementEventsForDashJS() {
    const mediaElement = this.mediaElement;

    if (!mediaElement) {
      return;
    }

    this.listenToMediaElementEvent('playing', () => {
      const {currentTime} = mediaElement;
      this.needsFirstPlayIntent = false;
      this.eventCallback(Event.TIMECHANGED, {
        currentTime,
      });
    });
  }

  public getPlayerName = () => Player.DASHJS;
  public getPlayerVersion = () => this.mediaPlayer.getVersion();

  /**
   * @override
   */
  public getMIMEType() {
    return MIMETypes.DASH;
  }
  /**
   * @override
   */
  public getStreamURL(): string | undefined {
    if (!this.mediaPlayer) {
      return;
    }

    const source = this.mediaPlayer.getSource();
    if (source) {
      return source.toString();
    }
  }
  /**
   * Implemented by sub-class to deliver current quality-level info
   * specific to media-engine.
   * @override
   * @returns {QualityLevelInfo}
   */
  public getCurrentQualityLevelInfo(): QualityLevelInfo | null {
    if (!this.mediaPlayer) {
      return null;
    }

    const videoBitrateInfoList = this.mediaPlayer.getBitrateInfoListFor('video');
    const currentVideoQualityIndex = this.mediaPlayer.getQualityFor('video');
    const currentVideoQuality = videoBitrateInfoList[currentVideoQualityIndex];

    if (currentVideoQuality == null) {
      return null;
    }

    const {width, height, bitrate} = currentVideoQuality;
    return {
      width,
      height,
      bitrate,
    };
  }
}
