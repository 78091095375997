import {CollectorConfig} from '../../types/AnalyticsConfig';
import {post} from '../../utils/Http';
import {ANALYTICS_BACKEND_BASE_URL} from '../../utils/Settings';
import {joinUrls} from '../../utils/Utils';
import {HttpRequest} from '../httprequesttracking/HttpRequest';
import {ErrorData} from './ErrorData';
import {ErrorDetail} from './ErrorDetail';

export class ErrorDetailBackend {
  public get queue(): ReadonlyArray<ErrorDetail> {
    return this._queue;
  }

  public get enabled(): boolean {
    return this._enabled;
  }

  public set enabled(value: boolean) {
    this._enabled = value;
  }

  public static copyErrorDetailTruncateStringsAndUrls(
    errorDetail: ErrorDetail,
    maxStringLength: number,
    maxUrlLength: number
  ): ErrorDetail {
    return {
      ...errorDetail,
      message: errorDetail.message?.substr(0, maxStringLength),
      data: this.copyErrorDataTruncateStrings(errorDetail.data, maxStringLength),
      httpRequests: errorDetail.httpRequests?.map((request) => this.copyHttpRequestTruncateUrls(request, maxUrlLength)),
    };
  }

  public static copyHttpRequestTruncateUrls(httpRequest: HttpRequest, maxLength: number): HttpRequest {
    return {
      ...httpRequest,
      url: httpRequest.url?.substr(0, maxLength),
      lastRedirectLocation: httpRequest.lastRedirectLocation?.substr(0, maxLength),
    };
  }

  public static copyErrorDataTruncateStrings(errorData: ErrorData, maxStringLength: number): ErrorData {
    return {
      ...errorData,
      exceptionMessage: errorData.exceptionMessage?.substr(0, maxStringLength),
      additionalData: errorData.additionalData?.substr(0, maxStringLength),
    };
  }

  public static copyErrorDetailTruncateHttpRequests(errorDetail: ErrorDetail, maxRequests: number): ErrorDetail {
    return {
      ...errorDetail,
      httpRequests: errorDetail.httpRequests?.slice(-maxRequests),
    };
  }

  private static MAX_URL_LENGTH = 200;
  private static MAX_STRING_LENGTH = 400;

  private readonly backendUrl: string;
  private readonly _queue: ErrorDetail[] = [];
  private _enabled: boolean = false;

  constructor(collectorConfig: CollectorConfig | undefined) {
    this.backendUrl = joinUrls(collectorConfig?.backendUrl ?? ANALYTICS_BACKEND_BASE_URL, '/analytics/error');
  }

  public limitHttpRequestsOfQueuedErrorDetails(max: number) {
    this._queue.forEach((value, index) => {
      this._queue[index] = ErrorDetailBackend.copyErrorDetailTruncateHttpRequests(value, max);
    });
  }

  public send(errorDetail: ErrorDetail) {
    const errorDetailCopy = ErrorDetailBackend.copyErrorDetailTruncateStringsAndUrls(
      errorDetail,
      ErrorDetailBackend.MAX_STRING_LENGTH,
      ErrorDetailBackend.MAX_URL_LENGTH
    );
    if (this.enabled) {
      post(this.backendUrl, errorDetailCopy, () => {});
    } else {
      this._queue.push(errorDetailCopy);
    }
  }

  public flush() {
    const copy = [...this._queue];
    let element = copy.shift();
    while (element != null) {
      this.removeFromQueue(element);
      this.send(element);
      element = copy.shift();
    }
  }

  public clear() {
    this._queue.splice(0, this._queue.length);
  }

  private removeFromQueue(element: ErrorDetail) {
    const index = this._queue.findIndex((value) => value === element);
    if (index >= 0) {
      this._queue.splice(index, 1);
    }
  }
}
