import {Subscribable} from '../../core/EventDispatcher';
import {logger} from '../../utils/Logger';
import {Queue} from '../../utils/Queue';
import {HttpRequest} from './HttpRequest';
import {OnDownloadFinishedEventObject} from './OnDownloadFinishedEventObject';

export class HttpRequestTracking {
  private static DEFAULT_MAX_REQUESTS = 10;
  private _maxRequests: number = HttpRequestTracking.DEFAULT_MAX_REQUESTS;
  private _httpRequestQueue = new Queue<HttpRequest>();

  public get maxRequests(): number {
    return this._maxRequests;
  }

  public get httpRequests(): ReadonlyArray<HttpRequest> {
    return this._httpRequestQueue.items;
  }

  constructor(private subscribables: Array<Subscribable<OnDownloadFinishedEventObject>>) {
    subscribables.forEach((subscribable) => subscribable.subscribe(this.onDownloadFinishedHandler));
  }

  public configure(maxRequests: number) {
    this._maxRequests = maxRequests;
    this._httpRequestQueue.limit(maxRequests);
  }

  public disable() {
    this.subscribables.forEach((subscribable) => subscribable.unsubscribe(this.onDownloadFinishedHandler));
    this._httpRequestQueue.clear();
  }

  public reset() {
    this._httpRequestQueue.clear();
  }

  private onDownloadFinishedHandler = (event: OnDownloadFinishedEventObject) => {
    try {
      logger.log(`HttpRequestTracking.onDownloadFinished: ${JSON.stringify(event.httpRequest)}`);
      this.addRequest(event.httpRequest);
    } catch (error) {
      logger.error(`HttpRequestTracking.onDownloadFinished: Error in handler`, error);
    }
  };

  private addRequest(httpRequest: HttpRequest) {
    this._httpRequestQueue.offer(httpRequest);
    this._httpRequestQueue.limit(this._maxRequests);
  }
}
