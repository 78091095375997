import {Subscribable} from '../../core/EventDispatcher';
import {CollectorConfig} from '../../types/AnalyticsConfig';
import {FeatureConfigContainer} from '../../types/FeatureConfigContainer';
import {logger} from '../../utils/Logger';
import {getCurrentTimestamp} from '../../utils/Utils';
import {VERSION} from '../../Version';
import {Feature} from '../Feature';
import {HttpRequestTracking} from '../httprequesttracking/HttpRequestTracking';
import {ErrorDetail} from './ErrorDetail';
import {ErrorDetailBackend} from './ErrorDetailBackend';
import {ErrorDetailTrackingConfig} from './ErrorDetailTrackingConfig';
import {OnErrorDetailEventObject} from './OnErrorDetailEventObject';

export interface ErrorDetailTrackingSettingsProvider {
  readonly licenseKey: string;
  readonly domain: string;
  readonly impressionId: string;
  readonly collectorConfig?: CollectorConfig;
}

export class ErrorDetailTracking extends Feature<FeatureConfigContainer, ErrorDetailTrackingConfig> {
  private errorIndex = 0;

  constructor(
    private settingsProvider: ErrorDetailTrackingSettingsProvider,
    private backend: ErrorDetailBackend,
    private subscribables: Array<Subscribable<OnErrorDetailEventObject>>,
    private httpRequestTracking: HttpRequestTracking | undefined
  ) {
    super();
    subscribables.forEach((subscribable) => subscribable.subscribe(this.onErrorHandler));
  }

  public reset(): void {
    this.httpRequestTracking?.reset();
    this.errorIndex = 0;
  }

  public enabled(): void {
    this.backend.enabled = true;
    this.backend.flush();
  }

  public disabled(): void {
    this.errorIndex = 0;
    this.httpRequestTracking?.disable();
    this.backend.clear();
    this.subscribables.forEach((subscribable) => subscribable.unsubscribe(this.onErrorHandler));
  }

  public configured(authenticated: boolean, config?: ErrorDetailTrackingConfig): void {
    const maxRequests = Math.max(config?.numberOfHttpRequests ?? 0, 0);
    this.httpRequestTracking?.configure(maxRequests);
    this.backend.limitHttpRequestsOfQueuedErrorDetails(maxRequests);
  }

  public extractConfig(configContainer: FeatureConfigContainer): ErrorDetailTrackingConfig | undefined {
    return configContainer.errorDetails;
  }

  private onErrorHandler = (event: OnErrorDetailEventObject) => {
    try {
      if (!this.isEnabled) {
        return;
      }

      const httpRequests = this.httpRequestTracking?.httpRequests;
      const httpRequestsCopy = httpRequests == null ? undefined : [...httpRequests];
      const errorIndex = this.errorIndex;
      this.errorIndex++;

      const errorDetail: ErrorDetail = {
        platform: 'web',
        licenseKey: this.settingsProvider.licenseKey,
        domain: this.settingsProvider.domain,
        impressionId: this.settingsProvider.impressionId,
        analyticsVersion: VERSION,
        errorId: errorIndex,
        timestamp: getCurrentTimestamp(),
        code: event.code,
        message: event.message,
        data: event.errorData ?? {
          additionalData: undefined,
          exceptionMessage: undefined,
          exceptionStacktrace: undefined,
        },
        httpRequests: httpRequestsCopy,
      };

      logger.log(`ErrorDetailTracking.onError: ${JSON.stringify(errorDetail)}`);
      this.backend.send(errorDetail);
    } catch (error) {
      logger.error(`ErrorDetailTracking.onError: Error in handler`, error);
    }
  };
}
