import * as Utils from '../utils/Utils';
import {CustomDataValues} from './CustomDataValues';
import {Sample} from './Sample';

export class AdAnalyticsSample implements CustomDataValues {
  public adModule?: string;
  public adModuleVersion?: string;
  public videoImpressionId?: string;
  public userAgent?: string;
  public language?: string;
  public cdnProvider?: string;
  public customData1?: string;
  public customData2?: string;
  public customData3?: string;
  public customData4?: string;
  public customData5?: string;
  public customData6?: string;
  public customData7?: string;
  public customData8?: string;
  public customData9?: string;
  public customData10?: string;
  public customData11?: string;
  public customData12?: string;
  public customData13?: string;
  public customData14?: string;
  public customData15?: string;
  public customData16?: string;
  public customData17?: string;
  public customData18?: string;
  public customData19?: string;
  public customData20?: string;
  public customData21?: string;
  public customData22?: string;
  public customData23?: string;
  public customData24?: string;
  public customData25?: string;
  public customUserId?: string;
  public experimentName?: string;
  public domain?: string;
  public key?: string;
  public path?: string;
  public player?: string;
  public playerKey?: string;
  public playerTech?: string;
  public screenHeight?: number;
  public screenWidth?: number;
  public version?: string;
  public size?: string;
  public userId?: string;
  public videoId?: string;
  public videoTitle?: string;
  public videoWindowHeight?: number;
  public videoWindowWidth?: number;
  public playerStartupTime?: number;
  public analyticsVersion?: string;
  public pageLoadTime?: number;
  public pageLoadType?: number;
  public autoplay?: boolean;
  public platform: string = 'web';
  public audioCodec?: string;
  public videoCodec?: string;

  constructor(sample?: Sample) {
    if (!sample) {
      return;
    }
    this.videoImpressionId = sample.impressionId;
    this.userAgent = sample.userAgent;
    this.language = sample.language;
    this.cdnProvider = sample.cdnProvider;

    Utils.transferCustomDataFields(sample, this);

    this.customUserId = sample.customUserId;
    this.domain = sample.domain;
    this.experimentName = sample.experimentName;
    this.key = sample.key;
    this.path = sample.path;
    this.player = sample.player;
    this.playerKey = sample.playerKey;
    this.playerTech = sample.playerTech;
    this.screenHeight = sample.screenHeight;
    this.screenWidth = sample.screenWidth;
    this.version = sample.version;
    this.size = sample.size;
    this.userId = sample.userId;
    this.videoId = sample.videoId;
    this.videoTitle = sample.videoTitle;
    this.videoWindowHeight = sample.videoWindowHeight;
    this.videoWindowWidth = sample.videoWindowWidth;
    this.audioCodec = sample.audioCodec;
    this.videoCodec = sample.videoCodec;
  }
}
