import {FeatureConfig} from './FeatureConfig';

export abstract class Feature<TConfigContainer, TConfig extends FeatureConfig> {
  private _config?: TConfig = undefined;
  private _isEnabled: boolean = true;

  public get config(): TConfig | undefined {
    return this._config;
  }

  public get isEnabled(): boolean {
    return this._isEnabled;
  }

  public abstract reset(): void;
  public abstract enabled(): void;
  public abstract disabled(): void;
  public abstract configured(authenticated: boolean, config?: TConfig): void;

  public disable() {
    this._isEnabled = false;
    this.disabled();
  }

  public configure(authenticated: boolean, configContainer?: TConfigContainer): TConfig | undefined {
    if (configContainer != null) {
      this._config = this.extractConfig(configContainer);
    }
    this.configured(authenticated, this._config);
    return this._config;
  }

  public abstract extractConfig(configContainer: TConfigContainer): TConfig | undefined;
}
