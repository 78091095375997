import {ErrorCode} from './ErrorCode';

export class VideoStartFailedReason {
  public static PAGE_CLOSED = new VideoStartFailedReason('PAGE_CLOSED', null);
  public static PLAYER_ERROR = new VideoStartFailedReason('PLAYER_ERROR', null);
  public static TIMEOUT = new VideoStartFailedReason('TIMEOUT', ErrorCode.VIDEO_STARTUP_TIMEOUT_REACHED);
  public static UNKNOWN = new VideoStartFailedReason('UNKNOWN', null);

  private constructor(public readonly reason: string, public readonly errorCode: ErrorCode | null) {}
}
