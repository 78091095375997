import * as StateMachine from 'javascript-state-machine';
import {Event, EventMap} from '../enums/Event';
import {AnalyticsStateMachineOptions} from '../types/AnalyticsStateMachineOptions';
import {NoExtraProperties} from '../types/NoExtraProperties';
import {StateMachineCallbacks} from '../types/StateMachineCallbacks';
import {logger, padRight} from '../utils/Logger';
import {AnalyticsStateMachine} from './AnalyticsStateMachine';

enum State {
  SETUP = 'SETUP',
  STARTUP = 'STARTUP',
  READY = 'READY',
  PLAYING = 'PLAYING',
  REBUFFERING = 'REBUFFERING',
  PAUSE = 'PAUSE',
  QUALITYCHANGE = 'QUALITYCHANGE',
  PAUSED_SEEKING = 'PAUSED_SEEKING',
  QUALITYCHANGE_PAUSE = 'QUALITYCHANGE_PAUSE',
  QUALITYCHANGE_REBUFFERING = 'QUALITYCHANGE_REBUFFERING',
  END = 'END',
  ERROR = 'ERROR',
  AD = 'AD',
  SOURCE_CHANGING = 'SOURCE_CHANGING',
  MUTING_READY = 'MUTING_READY',
  MUTING_PLAY = 'MUTING_PLAY',
  MUTING_PAUSE = 'MUTING_PAUSE',
  CASTING = 'CASTING',
  SUBTITLE_CHANGING = 'SUBTITLE_CHANGING',
  AUDIOTRACK_CHANGING = 'AUDIOTRACK_CHANGING',
  EXIT_BEFORE_VIDEOSTART = 'EXIT_BEFORE_VIDEOSTART',
  CUSTOMDATACHANGE = 'CUSTOMDATACHANGE',
}

export class HTML5AnalyticsStateMachine extends AnalyticsStateMachine {
  private seekStartedAt: any;

  constructor(stateMachineCallbacks: StateMachineCallbacks, opts: AnalyticsStateMachineOptions) {
    super(stateMachineCallbacks, opts);
    this.seekStartedAt = null;

    this.createStateMachine(opts);
  }

  public getAllStates() {
    return [
      ...Object.keys(State).map((key) => State[key]),
      'FINISH_QUALITYCHANGE_PAUSE',
      'FINISH_QUALITYCHANGE',
      'FINISH_QUALITYCHANGE_REBUFFERING',
    ];
  }

  public getAllStatesBut(states: string[]) {
    return this.getAllStates().filter((i) => states.indexOf(i) < 0);
  }

  public createStateMachine(opts: AnalyticsStateMachineOptions) {
    return StateMachine.create({
      initial: State.SETUP,
      error: (eventName, from, to, args, errorCode, errorMessage) => {
        logger.error('Error in statemachine: ' + errorMessage);
      },
      events: [
        {name: Event.TIMECHANGED, from: State.SETUP, to: State.SETUP},
        {name: Event.READY, from: [State.SETUP, State.ERROR, State.END, State.SOURCE_CHANGING], to: State.READY},
        {name: Event.READY, from: State.READY, to: State.READY},
        {name: Event.READY, from: State.STARTUP, to: State.STARTUP},

        {name: Event.PLAY, from: State.READY, to: State.STARTUP},

        {name: Event.ERROR, from: State.STARTUP, to: State.EXIT_BEFORE_VIDEOSTART},
        {name: Event.UNLOAD, from: State.STARTUP, to: State.EXIT_BEFORE_VIDEOSTART},
        {name: Event.VIDEOSTART_TIMEOUT, from: State.STARTUP, to: State.EXIT_BEFORE_VIDEOSTART},

        {name: Event.START_BUFFERING, from: State.STARTUP, to: State.STARTUP},
        {name: Event.END_BUFFERING, from: State.STARTUP, to: State.STARTUP},
        {name: Event.VIDEO_CHANGE, from: State.STARTUP, to: State.STARTUP},
        {name: Event.AUDIO_CHANGE, from: State.STARTUP, to: State.STARTUP},

        {name: Event.TIMECHANGED, from: State.READY, to: State.STARTUP},
        {name: Event.TIMECHANGED, from: State.STARTUP, to: State.PLAYING},
        {name: Event.TIMECHANGED, from: State.PLAYING, to: State.PLAYING},
        {name: Event.TIMECHANGED, from: State.PAUSE, to: State.PAUSE},
        {name: Event.TIMECHANGED, from: State.PAUSE, to: State.PAUSE},

        {name: Event.SEEKED, from: State.PAUSE, to: State.PAUSE},

        {name: Event.END_BUFFERING, from: State.PLAYING, to: State.PLAYING},
        {name: Event.START_BUFFERING, from: State.PLAYING, to: State.REBUFFERING},
        {name: Event.START_BUFFERING, from: State.REBUFFERING, to: State.REBUFFERING},

        {name: Event.PLAY, from: State.REBUFFERING, to: State.PLAYING},
        {name: Event.TIMECHANGED, from: State.REBUFFERING, to: State.PLAYING},

        // Ignoring since it's pushed in a live stream
        {name: Event.SEEK, from: State.STARTUP, to: State.STARTUP},
        {name: Event.PLAY, from: State.PAUSED_SEEKING, to: State.PAUSED_SEEKING},

        {name: Event.PAUSE, from: State.PLAYING, to: State.PAUSE},
        {name: Event.PAUSE, from: State.REBUFFERING, to: State.PAUSE},

        {name: Event.PLAY, from: State.PAUSE, to: State.PLAYING},
        {name: Event.TIMECHANGED, from: State.PAUSE, to: State.PLAYING},

        {name: Event.VIDEO_CHANGE, from: State.PLAYING, to: State.QUALITYCHANGE},
        {name: Event.AUDIO_CHANGE, from: State.PLAYING, to: State.QUALITYCHANGE},
        {name: Event.VIDEO_CHANGE, from: State.QUALITYCHANGE, to: State.QUALITYCHANGE},
        {name: Event.AUDIO_CHANGE, from: State.QUALITYCHANGE, to: State.QUALITYCHANGE},
        {name: 'FINISH_QUALITYCHANGE', from: State.QUALITYCHANGE, to: State.PLAYING},

        {name: Event.VIDEO_CHANGE, from: State.PAUSE, to: State.QUALITYCHANGE_PAUSE},
        {name: Event.AUDIO_CHANGE, from: State.PAUSE, to: State.QUALITYCHANGE_PAUSE},
        {
          name: Event.VIDEO_CHANGE,
          from: State.QUALITYCHANGE_PAUSE,
          to: State.QUALITYCHANGE_PAUSE,
        },
        {
          name: Event.AUDIO_CHANGE,
          from: State.QUALITYCHANGE_PAUSE,
          to: State.QUALITYCHANGE_PAUSE,
        },
        {name: 'FINISH_QUALITYCHANGE_PAUSE', from: State.QUALITYCHANGE_PAUSE, to: State.PAUSE},

        {name: Event.SEEK, from: State.PAUSE, to: State.PAUSED_SEEKING},
        {name: Event.SEEK, from: State.PAUSED_SEEKING, to: State.PAUSED_SEEKING},
        {name: Event.AUDIO_CHANGE, from: State.PAUSED_SEEKING, to: State.PAUSED_SEEKING},
        {name: Event.VIDEO_CHANGE, from: State.PAUSED_SEEKING, to: State.PAUSED_SEEKING},
        {name: Event.START_BUFFERING, from: State.PAUSED_SEEKING, to: State.PAUSED_SEEKING},
        {name: Event.END_BUFFERING, from: State.PAUSED_SEEKING, to: State.PAUSED_SEEKING},
        {name: Event.SEEKED, from: State.PAUSED_SEEKING, to: State.PAUSE},
        {name: Event.TIMECHANGED, from: State.PAUSED_SEEKING, to: State.PLAYING},
        {name: Event.PAUSE, from: State.PAUSED_SEEKING, to: State.PAUSE},

        {name: Event.END, from: State.PAUSED_SEEKING, to: State.END},
        {name: Event.END, from: State.PLAYING, to: State.END},
        {name: Event.END, from: State.PAUSE, to: State.END},
        {name: Event.PAUSE, from: State.END, to: State.END},
        {name: Event.SEEK, from: State.END, to: State.END},
        {name: Event.SEEKED, from: State.END, to: State.END},
        {name: Event.TIMECHANGED, from: State.END, to: State.END},
        {name: Event.END_BUFFERING, from: State.END, to: State.END},
        {name: Event.START_BUFFERING, from: State.END, to: State.END},
        {name: Event.END, from: State.END, to: State.END},

        // Ignored - Livestreams do a Seek during startup and SEEKED once playback started
        {name: Event.SEEKED, from: State.PLAYING, to: State.PLAYING},
        {name: Event.SEEK, from: State.PLAYING, to: State.PLAYING},

        {name: Event.PLAY, from: State.END, to: State.PLAYING},

        {name: Event.ERROR, from: this.getAllStatesBut([State.STARTUP]), to: State.ERROR},
        {name: Event.PAUSE, from: State.ERROR, to: State.ERROR},

        {name: Event.UNLOAD, from: this.getAllStatesBut([State.STARTUP]), to: State.END},

        {name: Event.SUBTITLE_CHANGE, from: State.PLAYING, to: State.SUBTITLE_CHANGING},
        {name: Event.SUBTITLE_CHANGE, from: State.PAUSE, to: State.PAUSE},
        {name: Event.SUBTITLE_CHANGE, from: State.READY, to: State.READY},
        {name: Event.SUBTITLE_CHANGE, from: State.STARTUP, to: State.STARTUP},
        {name: Event.SUBTITLE_CHANGE, from: State.REBUFFERING, to: State.REBUFFERING},
        {name: Event.SUBTITLE_CHANGE, from: State.SUBTITLE_CHANGING, to: State.SUBTITLE_CHANGING},
        {name: Event.TIMECHANGED, from: State.SUBTITLE_CHANGING, to: State.PLAYING},

        {name: Event.AUDIOTRACK_CHANGED, from: State.PLAYING, to: State.AUDIOTRACK_CHANGING},
        {name: Event.AUDIOTRACK_CHANGED, from: State.PAUSE, to: State.PAUSE},
        {name: Event.AUDIOTRACK_CHANGED, from: State.READY, to: State.READY},
        {name: Event.AUDIOTRACK_CHANGED, from: State.STARTUP, to: State.STARTUP},
        {name: Event.AUDIOTRACK_CHANGED, from: State.REBUFFERING, to: State.REBUFFERING},
        {name: Event.AUDIOTRACK_CHANGED, from: State.AUDIOTRACK_CHANGING, to: State.AUDIOTRACK_CHANGING},
        {name: Event.TIMECHANGED, from: State.AUDIOTRACK_CHANGING, to: State.PLAYING},

        {name: Event.START_AD, from: State.PLAYING, to: State.AD},
        {name: Event.END_AD, from: State.AD, to: State.PLAYING},

        {name: Event.MUTE, from: State.READY, to: State.MUTING_READY},
        {name: Event.UN_MUTE, from: State.READY, to: State.MUTING_READY},
        {name: 'FINISH_MUTING', from: State.MUTING_READY, to: State.READY},

        {name: Event.MUTE, from: State.PLAYING, to: State.MUTING_PLAY},
        {name: Event.UN_MUTE, from: State.PLAYING, to: State.MUTING_PLAY},
        {name: 'FINISH_MUTING', from: State.MUTING_PLAY, to: State.PLAYING},

        {name: Event.MUTE, from: State.PAUSE, to: State.MUTING_PAUSE},
        {name: Event.UN_MUTE, from: State.PAUSE, to: State.MUTING_PAUSE},
        {name: 'FINISH_MUTING', from: State.MUTING_PAUSE, to: State.PAUSE},

        {name: Event.START_CAST, from: [State.READY, State.PAUSE], to: State.CASTING},
        {name: Event.PAUSE, from: State.CASTING, to: State.CASTING},
        {name: Event.PLAY, from: State.CASTING, to: State.CASTING},
        {name: Event.TIMECHANGED, from: State.CASTING, to: State.CASTING},
        {name: Event.MUTE, from: State.CASTING, to: State.CASTING},
        {name: Event.SEEK, from: State.CASTING, to: State.CASTING},
        {name: Event.SEEKED, from: State.CASTING, to: State.CASTING},
        {name: Event.END_CAST, from: State.CASTING, to: State.READY},

        {name: Event.SEEK, from: State.READY, to: State.READY},
        {name: Event.SEEKED, from: State.READY, to: State.READY},
        {name: Event.SEEKED, from: State.STARTUP, to: State.STARTUP},

        {name: Event.SOURCE_LOADED, from: this.getAllStates(), to: State.SETUP},
        {name: Event.MANUAL_SOURCE_CHANGE, from: this.getAllStates(), to: State.SOURCE_CHANGING},
        {name: Event.TIMECHANGED, from: State.SOURCE_CHANGING, to: State.SOURCE_CHANGING},
        {name: Event.PAUSE, from: State.SOURCE_CHANGING, to: State.SOURCE_CHANGING},

        {name: Event.VIDEO_CHANGE, from: State.REBUFFERING, to: State.QUALITYCHANGE_REBUFFERING},
        {name: Event.AUDIO_CHANGE, from: State.REBUFFERING, to: State.QUALITYCHANGE_REBUFFERING},
        {
          name: Event.VIDEO_CHANGE,
          from: State.QUALITYCHANGE_REBUFFERING,
          to: State.QUALITYCHANGE_REBUFFERING,
        },
        {
          name: Event.AUDIO_CHANGE,
          from: State.QUALITYCHANGE_REBUFFERING,
          to: State.QUALITYCHANGE_REBUFFERING,
        },
        {
          name: 'FINISH_QUALITYCHANGE_REBUFFERING',
          from: State.QUALITYCHANGE_REBUFFERING,
          to: State.REBUFFERING,
        },

        {name: Event.CUSTOM_DATA_CHANGE, from: [State.PLAYING, State.PAUSE], to: State.CUSTOMDATACHANGE},
        {name: Event.PLAYING, from: State.CUSTOMDATACHANGE, to: State.PLAYING},
        {name: Event.PAUSE, from: State.CUSTOMDATACHANGE, to: State.PAUSE},

        {name: Event.PLAYLIST_TRANSITION, from: this.getAllStates(), to: State.STARTUP},
      ],
      callbacks: {
        onenterstate: (event, from, to, timestamp, eventObject) => {
          if (from === 'none' && opts.starttime) {
            this.onEnterStateTimestamp = opts.starttime;
          } else {
            this.onEnterStateTimestamp = timestamp || new Date().getTime();
          }

          logger.log('[ENTER] ' + padRight(to, 20) + 'EVENT: ' + padRight(event, 20) + ' from ' + padRight(from, 14));
          if (eventObject && to !== State.PAUSED_SEEKING) {
            this.stateMachineCallbacks.setVideoTimeStartFromEvent(eventObject);
          }

          if (event === Event.START_CAST && to === State.CASTING) {
            this.stateMachineCallbacks.startCasting(timestamp, eventObject);
          }

          if (to === State.REBUFFERING) {
            this.startRebufferingHeartbeatInterval();
          }
        },
        onafterevent: (event, from, to, timestamp, eventObject) => {
          if (event === Event.MANUAL_SOURCE_CHANGE) {
            this.stateMachineCallbacks.manualSourceChange(eventObject);
          }
          if (event === Event.PLAYLIST_TRANSITION) {
            this.stateMachineCallbacks.playlistTransition(eventObject);
          }
          if (to === State.QUALITYCHANGE_PAUSE) {
            this.stateMachine.FINISH_QUALITYCHANGE_PAUSE(timestamp);
          }
          if (to === State.QUALITYCHANGE) {
            this.stateMachine.FINISH_QUALITYCHANGE(timestamp);
          }
          if (to === State.QUALITYCHANGE_REBUFFERING) {
            this.stateMachine.FINISH_QUALITYCHANGE_REBUFFERING(timestamp);
          }
          if (to === State.MUTING_READY || to === State.MUTING_PLAY || to === State.MUTING_PAUSE) {
            this.stateMachine.FINISH_MUTING(timestamp);
          }
        },
        onleavestate: (event, from, to, timestamp, eventObject) => {
          if (from === State.REBUFFERING) {
            this.resetRebufferingHelpers();
          }

          if (!timestamp) {
            return;
          }

          const stateDuration = timestamp - this.onEnterStateTimestamp;

          if (eventObject && to !== State.PAUSED_SEEKING) {
            this.stateMachineCallbacks.setVideoTimeEndFromEvent(eventObject);
          }

          if (from === State.READY && to === State.STARTUP) {
            this.setVideoStartTimeout();
          } else if (from === State.STARTUP && to === State.PLAYING) {
            this.clearVideoStartTimeout();
          }

          const fnName = String(from).toLowerCase();
          if (to === State.EXIT_BEFORE_VIDEOSTART) {
            this.clearVideoStartTimeout();
            const eventData = this.getVideoStartupFailedEventData(timestamp, event, eventObject);
            const shouldSendSample = event !== Event.ERROR;
            this.stateMachineCallbacks.videoStartFailed(eventData, shouldSendSample);
          } else if (from === State.PAUSED_SEEKING) {
            const seekDuration = timestamp - this.seekStartedAt;
            this.seekStartedAt = null;
            this.stateMachineCallbacks[fnName](seekDuration, fnName, eventObject);
          } else if (event === Event.UNLOAD) {
            this.stateMachineCallbacks.unload(stateDuration, fnName);
          } else if (from === State.PAUSE && to !== State.PAUSED_SEEKING) {
            this.stateMachineCallbacks.setVideoTimeStartFromEvent(event);
            this.stateMachineCallbacks.pause(stateDuration, fnName);
          } else {
            const callbackFunction = this.stateMachineCallbacks[fnName];
            if (typeof callbackFunction === 'function') {
              callbackFunction(stateDuration, fnName, eventObject);
            } else {
              logger.error('Could not find callback function for ' + fnName);
            }
          }

          if (eventObject && to !== State.PAUSED_SEEKING) {
            this.stateMachineCallbacks.setVideoTimeStartFromEvent(eventObject);
          }

          if (event === Event.VIDEO_CHANGE) {
            this.stateMachineCallbacks.videoChange(eventObject);
          } else if (event === Event.AUDIO_CHANGE) {
            this.stateMachineCallbacks.audioChange(eventObject);
          } else if (event === Event.MUTE) {
            this.stateMachineCallbacks.mute();
          } else if (event === Event.UN_MUTE) {
            this.stateMachineCallbacks.unMute();
          }
        },
        onseek: (event, from, to, timestamp) => {
          this.seekStartedAt = this.seekStartedAt || timestamp;
        },
        ontimechanged: (event, from, to, timestamp, eventObject) => {
          const stateDuration = timestamp - this.onEnterStateTimestamp;

          if (stateDuration > 59700) {
            this.stateMachineCallbacks.setVideoTimeEndFromEvent(eventObject);

            this.stateMachineCallbacks.heartbeat(stateDuration, String(from).toLowerCase(), {played: stateDuration});
            this.onEnterStateTimestamp = timestamp;

            this.stateMachineCallbacks.setVideoTimeStartFromEvent(eventObject);
          }
        },
        onplayerError: (event, from, to, timestamp, eventObject) => {
          this.stateMachineCallbacks.error(eventObject);
        },
      },
    });
  }

  public callEvent<StatemachineEvent extends keyof EventMap, EventData extends EventMap[StatemachineEvent]>(
    eventType: StatemachineEvent,
    eventObject: NoExtraProperties<EventMap[StatemachineEvent], EventData>,
    timestamp: number
  ): void {
    const exec = this.stateMachine[eventType];

    if (exec) {
      exec.call(this.stateMachine, timestamp, eventObject);
    } else {
      logger.log('Ignored Event: ' + eventType);
    }
  }

  public sourceChange = (config: any, timestamp: number, currentTime?: number) => {
    this.callEvent(Event.MANUAL_SOURCE_CHANGE, {config, currentTime}, timestamp);
  };
}
