import {PlayerAPI, PlayerConfig} from 'bitmovin-player';
import {AdapterAPI} from '../api/AdapterAPI';
import {Analytics} from '../core/Analytics';
import {AnalyticsConfig} from '../types/AnalyticsConfig';
import {AnalyticsStateMachineOptions} from '../types/AnalyticsStateMachineOptions';
import {logger} from '../utils/Logger';
import {Adapter} from './Adapter';
import {Bitmovin8InternalAdapter} from './internal/Bitmovin8InternalAdapter';

export class Bitmovin8Adapter extends Adapter implements AdapterAPI {
  constructor(player: any, opts?: AnalyticsStateMachineOptions) {
    super();

    if (this.hasPlayerAlreadyBeenAugmented(player)) {
      logger.errorMessageToUser('Bitmovin Analytics is already hooked up to this player instance');
      return;
    }
    this.markPlayerInstanceAsAugmented(player);

    const playerConfig: PlayerConfig = (player as PlayerAPI).getConfig();
    let analyticsConfig: AnalyticsConfig = (playerConfig as any).analytics;
    if (analyticsConfig === undefined) {
      analyticsConfig = {};
    }

    analyticsConfig.playerKey = analyticsConfig.playerKey || playerConfig.key;

    this.internalAdapter = new Bitmovin8InternalAdapter(player, opts);
    this.analytics = new Analytics(analyticsConfig, this.internalAdapter);
    (player as any).analytics = this.analytics;
    this.wrapPlayerLoad(player, this.analytics);
  }

  private wrapPlayerLoad(player: PlayerAPI, analytics: Analytics) {
    const originalLoad = player.load;
    // @ts-ignore
    player.load = (...args: Parameters<typeof player.load>): Promise<void> => {
      if (args.length > 0) {
        const analyticsConfig = (args[0] as any).analytics;
        // we reset the analytics and reload with a new config
        analytics.sourceChange(analyticsConfig);
      }

      return originalLoad.apply(player, args);
    };
  }
}
